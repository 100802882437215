import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import MetaTags from 'react-meta-tags';
import { apiGetRequest } from '../util';
import Footer from '../components/Footer';
import { Stream } from "@cloudflare/stream-react";
import ShakaPlayer  from 'shaka-player-react';
import "../css/video.css";
import 'shaka-player/dist/controls.css';
interface Params {
    id: string
}

export default function Video(props: any) {
    let params: Params = useParams();
    const [state, setState] = useState({
        name: "",
        iframeUrl: ""
    });
    useEffect(() => {
        apiGetRequest(`/getvideo/${params.id}`).then(d => {
            if (d.data) {
                setState({
                    ...state,
                    name: d.data.name,
                    iframeUrl: d.data.iFrameUrl
                });
            }
        })
        // let blob = fetch("blob:https://embed.videodelivery.net/4f62d75d-70e0-4f54-919c-a25f42241553").then(r => r.blob()).then(r => console.log(r));
    }, [])
    async function handleClick(e: any) {
        const iframe = document.querySelector("iframe")!;
        console.log(iframe.contentWindow!.document.getElementsByTagName("iframe"));

    }



    return (
        <>
            <div className="video-page">
                <Container>
                    <div className="youclip-video-container">
                        <MetaTags>
                            <meta property="og:video" content={`https://videodelivery.net/${params.id}/manifest/video.mpd`} />
                            <meta property="og:video:url" content={`https://videodelivery.net/${params.id}/manifest/video.mpd`} />
                            <meta property="og:video:secure_url" content={`https://videodelivery.net/${params.id}/manifest/video.mpd`} />
                            <meta property="og:video:type" content="video/mp4" />
                            <meta property="og:video:height" content="360" />
                            <meta property="og:video:width" content="640" />
                        </MetaTags>
                            <>
                                <MetaTags>
                                    <title>{state.name.length > 0 ? state.name : "YouClip"}</title>
                                </MetaTags>
                                <div className="youclip-video">
                                    <ShakaPlayer src={`https://videodelivery.net/${params.id}/manifest/video.mpd` }></ShakaPlayer>
                                    <p className="lead-1 text-bold name">{state.name}</p>
                                    {/* <button onClick={handleClick}>Click Me!</button> */}
                                </div>
                            </>
                    </div>
                </Container>
                <Footer>
                    <p>© YouClip 2021 | High quality video sharing | Powered by Cloudflare Stream</p>
                </Footer>
            </div>
        </>
    )
    
}
