import React, { Props } from 'react';


var phantom = {
    display: 'block',
    padding: '20px',
    height: '60px',
    width: '100%',
}

function Footer(props: any) {
    return (
        <div>
            <div style={phantom} />
            <div style={{
                backgroundColor: "rgb(233, 233, 233)",
                borderTop: "1px solid rgb(240, 238, 238)",
                textAlign: "center",
                padding: "20px",
                position: "fixed",
                left: "0",
                bottom: "0",
                height: "60px",
                width: "100%",
            }}>
                {props.children}
            </div>
        </div>
    )
}

export default Footer