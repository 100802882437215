import axios, { AxiosRequestConfig } from 'axios';

// const API = process.env.NODE_ENV === "production" ? "https://backend.youclip.pro" : "https://81f7b8aa2392.ngrok.io";
const API = process.env.NODE_ENV === "production" ? "https://backend.youclip.pro" : "http://localhost:5555";
export async function apiGetRequest(path: string, options: AxiosRequestConfig = {}) {
    return axios.get(`${API}${path}`, options)
}

export async function workingApiPostRequest(path: string, data: any = {} ) {
    return axios(`${API}${path}`, {
        method: "POST",
        data: data
    });
}

export async function apiPostRequest(path: string, options: AxiosRequestConfig = {}) {
    options.method = "POST"
    return axios(`${API}${path}`, options);
}

export {}