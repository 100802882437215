import React from 'react';
import { Route, BrowserRouter } from 'react-router-dom';
import Upload from './pages/upload';
import Video from './pages/video';
import CookieConsent from "react-cookie-consent";

function App() {
    return (
        <>
            <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
            <BrowserRouter>
                <Route exact path="/" component={Upload}></Route>
                <Route path="/:id" component={Video}></Route>
            </BrowserRouter>
        </>
    );
}

export default App;