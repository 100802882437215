import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import {v4 as uuidv4} from 'uuid'
import '../css/upload.css';
import { apiGetRequest, apiPostRequest } from '../util';
import UploadButton from '../components/UploadButton';
import * as tus from 'tus-js-client';
import axios from 'axios';

interface Clip{
    id: string
    name: string
    state: string
}
export default class Upload extends Component {
    state = {
        name: "",
        uploading: false,
        clips: new Map<string, Clip>()
    }
    handleChange = (e: any) => {
        this.setState({
            ...this.state,
            name: e.target.value
        });
    }
    updateClip = (id: string, data: {}) => {
        const clips = this.state.clips;
        clips.set(id, {...this.state.clips.get(id)!, ...data});
        this.setState({
            ...this.state,
            clips: clips
        });
        window.localStorage.setItem("youclip-clips", JSON.stringify(Array.from(this.state.clips.entries())))
    }
    uploadFile = async (file: File) => {
        const clips = this.state.clips;
        const updateClip = this.updateClip;
        const setState = this.setState;
        const state = this.state;
        let uploadUrl = null;
        let clipId: any = null;
        try {
            // console.log(oneTimeUploadUrl);
            const upload = new tus.Upload(file, {
                endpoint: "https://youclip-tus.arthurlangley04.workers.dev",
                retryDelays: [0, 3000, 5000, 10000, 20000],
                chunkSize: 5242880,
                headers: {
                    // 'Upload-Length': String(file.size),
                    // "Tus-Resumable": "1.0.0"
                },
                metadata: {
                    filename: file.name,
                    filetype: file.type,
                    // allowedorigins: "http://localhost:3000",
                    // allowedOrigins: "localhost,localhost:3000",
                },
                _onUploadUrlAvailable: async () => {
                    clipId = (upload.url! as string).split("/")[4].split("?")[0];
                    updateClip(clipId, { id: clipId, name: file.name, state: "Uploading 0%" });
                    await apiPostRequest("/addvideo", {
                        data: {
                            name: file.name,
                            clipId: clipId
                        }
                    });
                },
                onProgress: function (bytesUploaded: number, bytesTotal: number) {
                    var percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
                    console.log(bytesUploaded, bytesTotal, percentage + "%")
                    if (clipId !== null) {
                        updateClip(clipId, {
                            state: `Uploading ${(bytesUploaded / bytesTotal * 100).toFixed(2)}`
                        });
                    }
                },
                onError: function (error: any) {
                    if (clipId !== null) {
                        updateClip(clipId, {
                            state: `Upload Failed`
                        });
                    }
                    console.log(error);
                },
                onSuccess: function () {
                    const clipId = (upload.url! as string).split("/")[4].split("?")[0];
                    if (clipId !== null) {
                        updateClip(clipId, {
                            state: `Uploaded`
                        });
                    }
                }
            } as any)
            upload.start();
            // let res = await fetch(oneTimeUploadUrl, { method: "POST", body: formData, });
            // this.updateClip(clipId, { state: "Uploaded" });
        } catch (e) {
            console.log(e);
        }

    }
    handleSubmit = async (e: any) => {
        // if (this.state.uploading) return;
        // try {
        //     e.preventDefault();
        //     const videoInput = document.getElementById("clipFile");
        //     const data = await apiGetRequest("/onetimeupload");
        //     const oneTimeUploadUrl = data.data.result.uploadURL;
        //     let video = (videoInput! as any).files[0] as File;
        //     const name = uuidv4() + "." + video.type.split("/")[1];
        //     const formData = new FormData();
        //     formData.append("file", video, name);
        //     try {
        //         document.getElementById("up-btn")!.innerText = "Uploading...";
        //         let res = await fetch(oneTimeUploadUrl, { method: "POST", body: formData, });
        //         await apiPostRequest("/addvideo", {
        //             data: {
        //                 name: this.state.name,
        //                 clipId: clipId
        //             }
        //         });
        //         document.getElementById("error")!.innerText = "Video has been uploaded.";
        //         document.getElementById("error")!.innerHTML = `<a href="https://youclip.pro/${clipId}" class="lead text-white">Click Here to see uploaded video</a>`;
        //         document.getElementById("up-btn")!.innerText = "Upload Complete!";
        //     } catch (e) {
        //         console.log(e)
        //         document.getElementById("error")!.innerText = "An Error Has Occurred";
        //     }
        // } catch (e) {
        //     console.log(e);
        //     document.getElementById("error")!.innerText = "An Error Has Occurred";
        // }
    }
    componentDidMount() {
        let clips = this.state.clips;
        if (window.localStorage.getItem("youclip-clips")) {
            clips = new Map(JSON.parse(window.localStorage.getItem("youclip-clips")!))
        }
        this.setState({
            ...this.state,
            clips: clips
        });
    }
    clips() {
        const clips = Array.from(this.state.clips, ([name, value]) => (value));
        return clips.map(clip => {
            return (
                <Card key={clip.id} className="hvr-grow youclip-card" style={{ width: '18rem', marginRight: "3em", marginBottom: "3em", maxWidth:"228px", maxHeight:"320px" }}>
                    <Card.Img style={{  maxWidth: "228px", maxHeight: "128.25px" }} variant="top"  src={clip.state === "Uploaded" ? `https://videodelivery.net/${clip.id}/thumbnails/thumbnail.jpg` : "https://acegif.com/wp-content/uploads/loading-11.gif"} />
                    <Card.Body>
                        <Card.Title>{clip.name}</Card.Title>
                        <Button className="youclip-card-btn" variant="primary" href={"/" + clip.id}>Open Clip</Button>
                        <Button className="youclip-card-btn" variant="primary" href={"/" + clip.id}>Copy URL</Button>
                        <p className="lead">{clip.state}</p>
                    </Card.Body>
                </Card>
            )
        })
    }
    render() {
        return (
            <div className="upload-page">
                <Container>
                    <Row>
                        <h4 className="display-4 youclip-title">YouClip.</h4>
                        <UploadButton onFileChange={(e: any) => this.uploadFile(e.target.files[0])}/>
                        <hr />
                    </Row>
                    <Row>
                        {this.state.clips.size > 0 && (
                            <>
                                {/* <h4 className="lead" style={{ whiteSpace: 'pre-line' }}>{"Your clips:\n"}</h4> */}
                                {this.clips()}
                            </>
                        )}
                        {this.state.clips.size === 0 && (
                            <>
                                <h4 className="lead">You currently have no clips to show, upload some to see them here.</h4>
                            </>
                        )}

                    </Row>
                </Container>
            </div>
        )
    }
}

{/* <div className="youclip-form">
                        <h1 className="lead-4 mb-4">Upload a new clip.</h1>
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Group>
                                <Form.File  accept="video/*" id="clipFile" label="Video File" required />
                            </Form.Group>
                            <Form.Group>
                                <Form.Control type="text" placeholder="Clip Name" required onChange={this.handleChange} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Control id="up-btn" className="hvr" type="submit" value="Upload" />
                            </Form.Group>
                            <p id="error" className="text-red lead"></p>
                        </Form>
                    </div> */}


// let response = await axios.get("https://youclip-tus.arthurlangley04.workers.dev", {
//     headers: {
//         'Upload-Length': file.size,
//         'Upload-Metadata': `filename ${btoa(file.name)} maxDurationSeconds ${btoa("3")}`
//     }
// });
// const oneTimeUploadUrl = response.headers["location"];
// console.log(oneTimeUploadUrl);
// const upload = new tus.Upload(file, {
//     endpoint: oneTimeUploadUrl,
//     retryDelays: [0, 3000, 5000, 10000, 20000],
//     metadata: {
//         filename: file.name,
//         filetype: file.type
//     },
//     onProgress: function (bytesUploaded, bytesTotal) {
//         var percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
//         console.log(bytesUploaded, bytesTotal, percentage + "%")
//     },
//     onError: function (error) {
//         console.log(error);
//     },
//     onSuccess: function () {
//         console.log("upload worked!");
//     }
// })
// upload.start();